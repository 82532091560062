





























import { defineComponent } from '@vue/composition-api'
import useNuxtImage from '@/composables/use-nuxt-image'
import ServiceLocation from '@/classes/ServiceLocation'

export default defineComponent({
  props: {
    location: {
      type: Object,
      required: true,
    },
    disableLink: {
      type: Boolean,
      default: false,
    },
  },
  setup () {
    const getLocationImgProps = (location: ServiceLocation) => useNuxtImage({
      placeholder: true,
      sizes: 'sm:100vw md:50vw lg:30vw xl:20vw',
      imgAttrs: {
        class: 'w-full h-56 object-cover',
        alt: location.img_alt,
      },
      loading: 'lazy',
      src: location.img,
    }).props

    return {
      getLocationImgProps,
    }
  },
})
