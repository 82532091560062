import { render, staticRenderFns } from "./IndexSectionLocations.vue?vue&type=template&id=53c55200&lang=pug&"
import script from "./IndexSectionLocations.vue?vue&type=script&lang=ts&"
export * from "./IndexSectionLocations.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IndexHeading: require('/home/runner/work/gg_code/gg_code/nuxt/components/index/IndexHeading.vue').default,Locations: require('/home/runner/work/gg_code/gg_code/nuxt/components/Locations.vue').default,IndexWrapperSection: require('/home/runner/work/gg_code/gg_code/nuxt/components/index/IndexWrapperSection.vue').default})
