import { render, staticRenderFns } from "./IndexSectionPromotion.vue?vue&type=template&id=5450e5ec&scoped=true&lang=pug&"
import script from "./IndexSectionPromotion.vue?vue&type=script&lang=ts&"
export * from "./IndexSectionPromotion.vue?vue&type=script&lang=ts&"
import style0 from "./IndexSectionPromotion.vue?vue&type=style&index=0&id=5450e5ec&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5450e5ec",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NuxtPicture: require('/home/runner/work/gg_code/gg_code/nuxt/components/NuxtPicture.vue').default,GCarousel: require('/home/runner/work/gg_code/gg_code/nuxt/components/GCarousel.vue').default,IndexWrapperSection: require('/home/runner/work/gg_code/gg_code/nuxt/components/index/IndexWrapperSection.vue').default})
