














import { defineComponent } from '@vue/composition-api'
import useBreakpoint from '@/composables/use-breakpoint'
import useNuxtImage from '@/composables/use-nuxt-image'

export default defineComponent({
  setup () {
    const { match } = useBreakpoint()
    const isMobile = !match('lg')

    const { props: imgProps } = useNuxtImage({
      placeholder: true,
      quality: 100,
      sizes: 'sm:100vw xl:100vw',
      imgAttrs: {
        class: 'w-full h-full absolute object-cover opacity-40 lg:opacity-100',
        alt: 'banner',
        fetchPriority: 'high',
      },
      preload: true,
      loading: 'eager',
      src: isMobile ? 'home/banner-m.jpg' : 'home/banner.jpg',
      sources: [isMobile ? 'home/banner-m.webp' : 'home/banner.webp', isMobile ? 'home/banner-m.jpg' : 'home/banner.jpg'],
    })

    return {
      imgProps,
    }
  },
})
