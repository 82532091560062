import { render, staticRenderFns } from "./IndexSectionCarousel.vue?vue&type=template&id=58aad57c&scoped=true&lang=pug&"
import script from "./IndexSectionCarousel.vue?vue&type=script&lang=ts&"
export * from "./IndexSectionCarousel.vue?vue&type=script&lang=ts&"
import style0 from "./IndexSectionCarousel.vue?vue&type=style&index=0&id=58aad57c&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58aad57c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NuxtPicture: require('/home/runner/work/gg_code/gg_code/nuxt/components/NuxtPicture.vue').default,GCarousel: require('/home/runner/work/gg_code/gg_code/nuxt/components/GCarousel.vue').default,HintGoPointsWhatIs: require('/home/runner/work/gg_code/gg_code/nuxt/components/hint/HintGoPointsWhatIs.vue').default,IndexWrapperSection: require('/home/runner/work/gg_code/gg_code/nuxt/components/index/IndexWrapperSection.vue').default})
