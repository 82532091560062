import { render, staticRenderFns } from "./DatePicker.vue?vue&type=template&id=27d6dcbb&lang=pug&"
import script from "./DatePicker.vue?vue&type=script&lang=ts&"
export * from "./DatePicker.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DatePickerBody: require('/home/runner/work/gg_code/gg_code/nuxt/components/date-picker/DatePickerBody.vue').default,GDialogOrMenu: require('/home/runner/work/gg_code/gg_code/nuxt/components/GDialogOrMenu.vue').default})
