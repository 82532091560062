import { render, staticRenderFns } from "./LocationPicker.vue?vue&type=template&id=54077891&scoped=true&lang=pug&"
import script from "./LocationPicker.vue?vue&type=script&lang=ts&"
export * from "./LocationPicker.vue?vue&type=script&lang=ts&"
import style0 from "./LocationPicker.vue?vue&type=style&index=0&id=54077891&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54077891",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LocationPickerBody: require('/home/runner/work/gg_code/gg_code/nuxt/components/location-picker/LocationPickerBody.vue').default,GDialogOrMenu: require('/home/runner/work/gg_code/gg_code/nuxt/components/GDialogOrMenu.vue').default})
