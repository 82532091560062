



























import { defineComponent, nextTick, onMounted, ref } from '@vue/composition-api'
import { useEventListener } from '@vueuse/core'
// @ts-ignore
import Slide from 'vue-carousel/src/Slide.vue'

import GCarousel from '@/components/GCarousel.vue'
import useIndexPromotion, { PromotionItem } from '@/composables/index/use-index-promotion'

import useNuxtImage from '@/composables/use-nuxt-image'

export default defineComponent({
  components: {
    GCarousel,
    Slide,
  },
  setup () {
    const getPromotionImgProps = (item: PromotionItem) => useNuxtImage({
      placeholder: true,
      sizes: 'xl:100vw xxl:60vw',
      imgAttrs: {
        class: 'slide-image w-full object-cover rounded-xl',
        alt: item.alt,
        fetchPriority: 'low',
      },
      src: item.img,
      loading: 'lazy',
    }).props

    const carousel = ref<Vue | null>(null)

    const { list } = useIndexPromotion()

    const clickPrevious = (e: Event) => {
      e.stopPropagation()
    }
    const clickNext = (e: Event) => {
      e.stopPropagation()
    }

    onMounted(async () => {
      await nextTick()
      const el = carousel.value?.$el
      const previous = el?.querySelector('.VueCarousel-navigation-prev')
      const next = el?.querySelector('.VueCarousel-navigation-next')

      useEventListener(previous, 'click', clickPrevious)
      useEventListener(next, 'click', clickNext)
    })

    return {
      carousel,
      list,

      getPromotionImgProps,
    }
  },
})
