export type PromotionItem = {
  img: string,
  title: string,
  href?: string,
  alt?: string,
  id?: string
}

export default function () {
  const list: PromotionItem[] = [{
    img: 'https://cdn.gogoout.com/homepage/homepage_bottom.png',
    title: 'gogoout_app',
    href: 'https://itunes.apple.com/app/id1636294527',
    alt: 'gogoout\'s app',
    id: 'index__promotion__app',
  }]

  return {
    list,
  }
}
